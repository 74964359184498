<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header">
                <div class="content__header-left">
                    <h1 class="heading-page-h1">{{ $translate('rules') }}</h1>
                </div>
                <div class="content__header-right">
                    <BluePlusButton to="/rules/add?tab=info">{{ $translate('add-a-rule') }}</BluePlusButton>
                </div>
            </div>
            <TableAdvanced 
                v-model="sortCol" 
                :rows="filteredRows" 
                :columns="columns" 
                :isCheckedAll="isCheckedAll"
                :checkedInputs="checkedInputs"
                @toggleAllCheckedInputs="toggleAllCheckedInputs"
            >
                <template v-slot:filters>
                    <div class="filter-options__search filter-options__search--md" >
                        <input type="search" class="input bg-left search-icon" placeholder="Search" 
                                v-model="rowsFilters.search"/>
                    </div>
                </template>
                <template v-slot:row="slotData">
                    <td>
                        <label class="module__check">
                            <input type="checkbox" name="privacy_policy"  :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event,slotData.r.id)"/>
                            <span class="check"></span>
                            <span class="text">{{slotData.r.name}}</span>
                        </label>
                    </td>
                    <td><div class="text-center">{{slotData.r.IOTDevices}}</div></td>
                    <td class="text-end">
                        <div class="table-actions flex items-center justify-end">
                            <div class="toggle-check" @click="toggleRuleActive(slotData.r.id,slotData.r.status)">
                                <input type="checkbox" name="toggle-check" :checked="slotData.r.status==='enabled'"/>
                                <span class="toggle-check__body-wrapper" >
                                    <span class="toggle-check__body">
                                        <span class="toggle-check__hendler"></span>
                                    </span>
                                    <span class="text-check">{{ $translate('enabled') }}</span>
                                    <span class="text-not-check">{{ $translate('disabled') }}</span>
                                </span>
                            </div>
                            <router-link :to="'/rules/'+slotData.r.id" class="btn btn-icon">
                                <inline-svg :src="require(`@/assets/img/ico-gear.svg`)" />
                            </router-link>
                            <button type="button" class="btn btn-icon red-color" @click="confirmDelete(slotData.r.id,'Delete Rule?')">
                                <inline-svg :src="require(`@/assets/img/trash-delete.svg`)" />
                            </button>
                        </div>
                    </td>
                </template>
            </TableAdvanced>
         
        </main>
        <Pagination v-model="filteredRows" :items="rawSource" />
    
    </div>
</template>

<script>
import { API } from "aws-amplify";
import { updateRules } from "@/graphql/mutations";

import {mixTables,mixDeleteItem} from "@/mixins"
import Base from "@/components/base";
import Popups from "@/components/popups";
import Buttons from "@/components/buttons";

export default {
    name: "Rules",
    components: {
        ...Base,
        ...Popups,
        ...Buttons
    },
    mixins:[mixTables,mixDeleteItem],
    // watch:{
    //     '$store.state.rules': {
    //         immediate: true ,
    //         deep: true ,
    //         handler(newVal, oldVal) {
    //             // функция вотча
    //             console.log('s',newVal);
    //         }
    //     }

    // },
    data() {
        return {
            source: "rules",
            columns: [
                { name: "Rule", filter: "name",class:"w-100" },
                { name: "IOT Devices", filter: "IOTDevices" },
                { name: "Actions",class:"text-end" },
                // { name: "Product Name", filter: "title" },
                // { name: "RFID №", filter: "rfid_number" },
                // { name: "Serial", filter: "serial_number" },
                // { name: "Device", filter: "deviceName" },
                // { name: "Status", filter: "status" },
                // { name: "Manufacturer", filter: "test_is_calc_field" },
                // { name: "Manufacturing Date", filter: "manufacturing_date" },
            ],
            // calculatedFields:[{
			// 	filter: 'IOTDevices',
			// 	path: ['devices','items'],
			// 	calcFunction: (arr)=>arr.length
			// }],
            deleteQueryType:"deleteRules",
            
        };
    },
    computed: {
        rules() {
            console.log("rules");
            return this.$store.state.rules || [];
        },
        testData:{
            get(){
                return true
            },
            set(ev){
                console.log(event)
            }
        },
     
    },
    methods: {
        async additionalDeleteActions(){
            let rule=this.rules.find(o=>{
                return o.id==this.deletePopupInfo.id  
            })
            if(rule){
                await rule.devices.items.map(o=>{
                     this.deleteQuery("deleteDeviceRules",o.id)
                })
            }
        },

        async toggleRuleActive(id,status){
            console.log((status=="enabled"?"disabled":"enabled"));            
            const rule = await API.graphql({
				query: updateRules,
                variables: {
                    input: {
                        id: id,
                        status: (status=="enabled"?"disabled":"enabled")
                    },
                },
			}).then(res => res.data.updateRules)
			this.$store.commit('UPDATE_ITEM', { field: 'rules', item: rule })
        },
    },
};
</script>
